/* eslint-disable react/react-in-jsx-scope */
import "@stripe/stripe-js";

import { ChakraProvider } from "@chakra-ui/react";
import {
  FBPixelProvider,
  FBPixelScript,
} from "@rivercode/facebook-conversion-api-nextjs/components";
import { ConfigInput } from "@the-volte/svc-core-sdk/lib/types";
import mobile from "is-mobile";
import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { positions } from "react-alert";

import { TikTokPixelProvider } from "@app/providers/TikTokPixelProvider";
import { TikTokScript } from "@app/scripts/TikTokScript";
import { ZendeskScript } from "@app/scripts/ZendeskScript";
import {
  loadMessagesJson,
  Locale,
  LocaleMessages,
} from "@components/legacy/Locale";
import { defaultTheme, GlobalStyle } from "@styles";
import { accentBlue } from "@styles/constants";
import { apiUrl, channelSlug, ssrMode } from "@temp/constants";
import { GTMScript } from "@utils/gtm";
import { getSaleorApi, getShopConfig, ShopConfig } from "@utils/ssr";

import packageJson from "../../package.json";
import { SaleorSSRProvider } from "../SaleorSSRProvider";

import "@temp/globalStyles/scss/index.scss";

const NextNProgress = dynamic(() => import("nextjs-progressbar"), {
  ssr: false,
});

const AlertProvider = dynamic(() =>
  import("react-alert").then((mod) => mod.Provider)
);

const NotificationTemplate = dynamic(
  () => import("@components/atoms").then((mod) => mod.NotificationTemplate),
  { ssr: false }
);

const NextQueryParamProvider = dynamic(() =>
  import("@components/legacy").then((mod) => mod.NextQueryParamProvider)
);

const LocaleProvider = dynamic(() =>
  import("@components/legacy/Locale").then((mod) => mod.LocaleProvider)
);

// This dynanmic import makes the _app chunk 200kb smaller!
const StorefrontApp = dynamic(() => import("@temp/app").then((mod) => mod.App));

if (!ssrMode) {
  window.version = packageJson.version;
}

const saleorConfig: ConfigInput = { apiUrl, channel: channelSlug };

const notificationConfig = { position: positions.BOTTOM_RIGHT, timeout: 2500 };

type CustomAppProps = AppProps &
  ShopConfig & { messages: LocaleMessages } & { clientIp: string };

// @ts-ignore
const CustomApp = ({
  Component,
  pageProps,
  footer,
  mainMenu,
  messages,
  shopConfig,
  shop,
  router,
  clientIp,
  siteSetting,
}: CustomAppProps) => (
  <>
    <FBPixelScript />
    <GTMScript />
    <ZendeskScript />
    <TikTokScript />
    <NextNProgress color={accentBlue} />
    <Head>
      <title>Dress Hire | Australia&apos;s Largest Dress Hire Selection</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#4158ff" />
      <meta name="msapplication-TileColor" content="#4158ff" />
      <meta name="theme-color" content="#ffffff" />
      {router?.asPath?.startsWith("/account") && (
        <meta name="robots" content="noindex" />
      )}
      <link
        rel="preload"
        href="/fonts/beausite-classic/BeausiteClassicWeb-Regular.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/beausite-classic/BeausiteClassicWeb-Medium.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/beausite-classic/BeausiteClassicWeb-Bold.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Head>
    <FBPixelProvider>
      <ChakraProvider theme={defaultTheme}>
        <AlertProvider
          template={NotificationTemplate as any}
          {...notificationConfig}
        >
          <LocaleProvider messages={messages}>
            <GlobalStyle />
            <NextQueryParamProvider>
              <SaleorSSRProvider config={saleorConfig}>
                <TikTokPixelProvider clientIp={clientIp}>
                  <StorefrontApp
                    footer={footer}
                    mainMenu={mainMenu}
                    shopConfig={shopConfig}
                    shop={shop}
                    siteSetting={siteSetting}
                  >
                    <Component {...pageProps} />
                  </StorefrontApp>
                </TikTokPixelProvider>
              </SaleorSSRProvider>
            </NextQueryParamProvider>
          </LocaleProvider>
        </AlertProvider>
      </ChakraProvider>
    </FBPixelProvider>
  </>
);

let shopConfig: ShopConfig | null = null;
let shopConfigLastFetched = new Date(0).getTime();

CustomApp.getInitialProps = async (appContext: AppContext) => {
  const {
    router: { locale },
    ctx: { req },
  } = appContext;
  const appProps = await App.getInitialProps(appContext);
  let messages: LocaleMessages;
  const clientIp =
    req?.headers?.["x-forwarded-for"] ||
    req?.headers?.["x-real-ip"] ||
    req?.socket?.remoteAddress ||
    "0.0.0.0";

  // Perform any setup required to render the page tree while being rendered on
  // the server, including initialising API access (instead of doing this inside
  // the provider which doesn't support async) and shimming out various global
  // state that the seed template relies upon.
  if (ssrMode) {
    // Never query menus etc more than once a minute (e.g. avoid rate limit during SSG build)
    const timeNow = new Date().getTime();
    if (!shopConfigLastFetched || timeNow - shopConfigLastFetched > 60000) {
      shopConfig = await getShopConfig();
      shopConfigLastFetched = timeNow;
    }

    // Init Apollo connection for use in SSR render. Bind to the global object
    // such that when SSR is detected by the provider it can provide a reference
    // to this API instance to each of the query components during render.
    global.saleorApi = await getSaleorApi();
    global.ssrMode = ssrMode;

    // Create reference for testing desktop vs mobile in SSR
    global.isDesktop = !mobile({ ua: req?.headers?.["user-agent"] });

    global.location = { href: "" };
    global.document = {
      body: { style: {} },
      getElementById: () => null,
    };

    global.matchMedia = () => ({ addListener: () => null });

    messages = await loadMessagesJson(locale as Locale);
  }

  return {
    ...appProps,
    ...shopConfig,
    messages,
    clientIp: Array.isArray(clientIp) ? clientIp[0] : clientIp,
  };
};

// ts-prune-ignore-next
export default CustomApp;
